import React from "react"

import location from "../images/bx_bx-current-location.png"
import phone from "../images/Vector (3).png"
import vector from "../images/Source_File_Mark 1 3.png"
import vector2 from "../images/clock.png"

const mapSection = () => {
  return (
    <div className="justify-center flex flex-col md:flex-row bg-white z-0">
      <section className="px-8 py-12 w-full bg-white 2xl:grid 2xl:grid-cols-3 2xl:px-28 2xl:gap-10    xl:grid xl:grid-cols-3 xl:px-22 xl:gap-9    lg:grid lg:grid-cols-3 lg:px-16 lg:gap-8    md:grid md:grid-cols-3 md:px-12 md:gap-6   sm:grid sm:grid-cols-3 sm:px-10 sm:gap-6 xl-max-w-1200 justify-center">
        <div className="col-span-2 ">
          <div className="">
            <div className="flex items-center mb-3">
              <img src={vector} alt="" className=" p-2 w-10 md:w-12" />
              <h1 className="text-navyBlue font-bold   2xl:text-2xl    xl:text-xl    lg:text-base    md:text-sm    sm:text-sm    text-sm Moderat-Extended-bold">
                Spice Brew Chiswick
              </h1>
            </div>
            <div class="mapouter">
              <div class="gmap_canvas">
                <iframe
                  title="google-map"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2483.804625790085!2d-0.27458108423034483!3d51.49845267963375!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48760e0ddba9667d%3A0x50c181fe1338c8c7!2s6%20Greenock%20Rd%2C%20London%20W3%208DU%2C%20UK!5e0!3m2!1sen!2sin!4v1620629490015!5m2!1sen!2sin"
                  className="w-full h-80 shadow-lg outline-green rounded-xl"
                  frameborder="0"
                  scrolling="no"
                  marginheight="0"
                  marginwidth="0"
                ></iframe>
              </div>
            </div>
          </div>
        </div>

        <div className="col-span-1 flex justify-center items-center h-36 md:h-auto">
          <div className="grid grid-cols-1  md:gap-4 gap-2 2xl:mt-8   xl:mt-7    lg:mt-6    md:mt-5    sm:mt-5    mt-5">
            <div className="flex justify-start items-start gap-1">
              <img src={vector2} alt="clock icon" className="w-5" />
              <div className="ml-1">
                <h1 className="text-navyBlue    2xl:text-base    xl:text-base    lg:text-base    md:text-sm    sm:text-sm    text-xs    Moderat-Extended-bold">
                  Working Hours
                </h1>
                <p className="text-navyBlue Moderat-Extended-bold 2xl:text-base    xl:text-base    lg:text-base    md:text-sm    sm:text-sm    text-xs">
                  12 PM - 2:30 PM
                </p>
                <p className="text-navyBlue Moderat-Extended-bold 2xl:text-base    xl:text-base    lg:text-base    md:text-sm    sm:text-sm    text-xs">
                  5 PM - 10 PM
                </p>
              </div>
            </div>
            <div className="flex justify-start items-start gap-1">
              <img src={location} alt="location" />
              <h1 className="text-navyBlue    2xl:text-base    xl:text-base    lg:text-base    md:text-sm    sm:text-sm    text-xs    Moderat-Extended-bold">
                K11, 6A Greenock Road,<br></br>
                London W3 8DU
              </h1>
            </div>
            <div className="flex justify-start items-start">
              <div className="flex items-cemter justify-between">
                <img className="px-1" src={phone} alt="phone" />
                <p className="text-navyBlue 2xl:text-base    xl:text-base    lg:text-base    md:text-sm    sm:text-xs    text-sm Moderat-Extended-bold">
                  0798 3873580
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* 2nd location */}

      <section className="px-8 py-12 w-full bg-white 2xl:grid 2xl:grid-cols-3 2xl:px-28 2xl:gap-10    xl:grid xl:grid-cols-3 xl:px-22 xl:gap-9    lg:grid lg:grid-cols-3 lg:px-16 lg:gap-8    md:grid md:grid-cols-3 md:px-12 md:gap-6   sm:grid sm:grid-cols-3 sm:px-10 sm:gap-6 xl-max-w-1200 justify-center">
        <div className="col-span-2 ">
          <div className="">
            <div className="flex items-center mb-3">
              <img src={vector} alt="" className=" p-2 w-10 md:w-12" />
              <h1 className="text-navyBlue font-bold   2xl:text-2xl    xl:text-xl    lg:text-base    md:text-sm    sm:text-sm    text-sm Moderat-Extended-bold">
                Spice Brew Uxbridge
              </h1>
            </div>
            <div class="mapouter">
              <div class="gmap_canvas">
                <iframe
                  title="google-map"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2480.8689851045033!2d-0.452855310940378!3d51.55230136684621!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48766dcbb5628ac3%3A0x963603072d31c346!2s12%20Hercies%20Rd%2C%20Uxbridge%20UB10%209NA!5e0!3m2!1sen!2suk!4v1622715118129!5m2!1sen!2suk"
                  className="w-full h-80 shadow-lg outline-green rounded-xl"
                  frameborder="0"
                  scrolling="no"
                  marginheight="0"
                  marginwidth="0"
                ></iframe>
              </div>
            </div>
          </div>
        </div>

        <div className="col-span-1 flex justify-center items-center h-36 md:h-auto">
          <div className="grid grid-cols-1  md:gap-4 gap-2 2xl:mt-8   xl:mt-7    lg:mt-6    md:mt-5    sm:mt-5    mt-5">
            <div className="flex justify-start items-start gap-1">
              <img src={vector2} alt="clock icon" className="w-5" />
              <div className="ml-1">
                <h1 className="text-navyBlue    2xl:text-base    xl:text-base    lg:text-base    md:text-sm    sm:text-sm    text-xs    Moderat-Extended-bold">
                  Working Hours
                </h1>

                <p className="text-navyBlue Moderat-Extended-bold 2xl:text-base    xl:text-base    lg:text-base    md:text-sm    sm:text-sm    text-xs">
                  5 PM - 10 PM
                </p>
              </div>
            </div>
            <div className="flex justify-start items-start gap-1">
              <img src={location} alt="location" />
              <h1 className="text-navyBlue    2xl:text-base    xl:text-base    lg:text-base    md:text-sm    sm:text-sm    text-xs    Moderat-Extended-bold">
                12 Hercies Road,<br></br>
                Hillingdon UB10 9NA
              </h1>
            </div>
            <div className="flex justify-start items-start">
              <div className="flex items-cemter justify-between">
                <img className="px-1" src={phone} alt="phone" />
                <p className="text-navyBlue 2xl:text-base    xl:text-base    lg:text-base    md:text-sm    sm:text-xs    text-sm Moderat-Extended-bold">
                  01895 270538
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default mapSection

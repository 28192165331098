import { Link } from "gatsby"
import React from "react"
import logo from "../images/sblogo.png"
import vector1 from "../images/fb.png"
import vector2 from "../images/insta-icon.jpg"
import vector3 from "../images/Vector (2).png"

const Footer = () => {
  return (
    <footer
      className="bg-white z-10 relative "
      style={{ boxShadow: "0px -4px 30px 5px rgba(0, 0, 0, 0.1)" }}
    >
      <div className="2xl:grid 2xl:grid-cols-3    xl:grid xl:grid-cols-3    lg:grid lg:grid-cols-3     md:grid md:grid-cols-3    sm:grid sm:grid-cols-3    ">
        <div className="flex justify-center items-center  ">
          <Link to="/">
            <img
              className="2xl:w-32 2xl:mt-0   xl:w-32 xl:mt-0     lg:w-32 lg:mt-0     md:w-28 md:mt-0    sm:w-20 sm:mt-0  mt-6  w-20"
              src={logo}
              alt=""
            />
          </Link>
        </div>

        <div className="md:p-12 p-5   w-full bg-white      2xl:grid 2xl:grid-cols-2 2xl:col-span-2      xl:grid-cols-2 xl:col-span-2      lg:grid-cols-2 lg:col-span-2     md:grid md:grid-cols-2 md:col-span-2     sm:grid sm:grid-cols-2 sm:col-span-2     grid grid-cols-2 col-span-2    ">
          <div className="grid grid-cols-2 col-span-2 leading-0">
            <div className="flex flex-col space-y-4 justify-end    2xl:hidden    xl:hidden    lg:hidden    md:hidden    sm:hidden    flex">
              <div className="fbImg flex items-start ">
                <img className="w-3 mr-2" src={vector1} alt="" />
                <a
                  className="footer-link 2xl:text-base    xl:text-base   text-text Moderat-Extended-bold"
                  href="https://www.facebook.com/spicebrewlondon"
                >
                  FACEBOOK
                </a>
              </div>
              <div className="instaImg flex items-start">
                <img className="w-3 mr-2" src={vector2} alt="" />
                <a
                  className="footer-link  2xl:text-base    xl:text-base  text-text Moderat-Extended-bold"
                  href="https://www.instagram.com/spice.brew"
                >
                  INSTAGRAM
                </a>
              </div>
              <div className="flex items-start">
                <img className="w-3 mr-2" src={vector3} alt="" />
                <a
                  className="footer-link 2xl:text-base    xl:text-base   text-text Moderat-Extended-bold"
                  href="https://twitter.com/spice_brew"
                >
                  TWITTER
                </a>
              </div>
            </div>
            <div className="flex flex-col space-y-4 flex    2xl:items-start     xl:items-start     lg:items-start     md:items-start     sm:items-start      items-end">
              <a
                className="footer-link   2xl:text-base    xl:text-base    lg:text-base    md:text-base    sm:text-xs    text-xs text-text Moderat-Extended-bold"
                href="https://spice-brew.square.site/privacy-policy"
              >
                PRIVACY
              </a>
              <a
                className="footer-link   2xl:text-base    xl:text-base    lg:text-base    md:text-base    sm:text-xs    text-xs text-text Moderat-Extended-bold"
                href="https://spice-brew.square.site/terms-and-conditions"
              >
                TERMS
              </a>
              <a
                className="footer-link   2xl:text-base    xl:text-base    lg:text-base    md:text-base    sm:text-xs    text-xs text-text Moderat-Extended-bold"
                href="https://spice-brew.square.site/contact-us"
              >
                CONTACT
              </a>
            </div>
            <div className=" flex-col space-y-4 justify-center    2xl:flex    xl:flex    lg:flex    md:flex    sm:flex    hidden">
              <div className="flex items-center">
                <img
                  className="2xl:w-6   xl:w-6    lg:w-6    md:w-5    sm:w-4    w-4   mr-2"
                  src={vector1}
                  alt=""
                />
                <a
                  className="footer-link      xl:text-base    lg:text-base    md:text-base    sm:text-xs    text-xs text-text Moderat-Extended-bold"
                  href="https://www.facebook.com/spicebrewlondon"
                >
                  FACEBOOK
                </a>
              </div>
              <div className="flex items-center">
                <img
                  className="2xl:w-6   xl:w-6    lg:w-6    md:w-5    sm:w-4    w-4 mr-2"
                  src={vector2}
                  alt=""
                />
                <a
                  className="footer-link      xl:text-base    lg:text-base    md:text-base    sm:text-xs    text-xs text-text Moderat-Extended-bold"
                  href="https://www.instagram.com/spice.brew"
                >
                  INSTAGRAM
                </a>
              </div>
              <div className="flex items-center">
                <img
                  className="2xl:w-6   xl:w-6    lg:w-6    md:w-5    sm:w-4    w-4 mr-2"
                  src={vector3}
                  alt=""
                />
                <a
                  className="footer-link      xl:text-base    lg:text-base    md:text-base    sm:text-xs    text-xs text-text Moderat-Extended-bold"
                  href="https://twitter.com/spice_brew"
                >
                  TWITTER
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
